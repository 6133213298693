<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试计划</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf">题库列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >题库详情</a
          >
        </span>
      </div>
      <div class="framePage-halfBody">
        <div class="framePage-article">
          <div class="ovy-a">
            <div>
              <div>
                <div class="title">
                  <h3>基本信息</h3>
                </div>
                <div class="titleMation">
                  <span>题库名称:{{ questionbankName }}</span>
                  <span>培训类型:{{ categoryName }}</span>
                </div>
              </div>
              <div>
                <div class="title">
                  <h3>题库类型</h3>
                  <el-button
                    type="text"
                    class="title-button"
                    @click="clickTap(-1, '')"
                    >全部</el-button
                  >
                </div>
                <div class="searchBox">
                  <el-input
                    v-model="questionTitle"
                    type="text"
                    size="small"
                    placeholder="请输入题目内容"
                    clearable
                  >
                    <el-button slot="append" class="bgc-bv" @click="getData()"
                      >搜索</el-button
                    ></el-input
                  >
                </div>
                <div class="searchqusetionBox">
                  <div
                    v-for="(item, index) in items"
                    :key="index"
                    @click="clickTap(index, item.id)"
                  >
                    <span :class="currentClass(index)">
                      <img :src="item.img" />
                      {{ item.name }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- <div class="btnBox">
                <el-button type="primary" class="bgc-bv" @click="addquestion"
                  >新增试题</el-button
                >
                <el-button type="primary" class="bgc-bv" @click="BatchUpload"
                  >批量添加</el-button
                >
              </div> -->
            </div>
          </div>
        </div>
        <div class="framePage-body">
          <div class="ovy-a">
            <div class="searchbox">
              <div class="flexcb searchbox" style="justify-content:center">
                <h2 style="text-align:center">{{ questionbankName }}</h2>
              </div>
            </div>
            <div class="framePage-scroll flexdc" :style="!tableData.length ?'height:93%':''">
              <div class="ovy-a flex1" id="list-box">
                <div
                  class="list hoverShow"
                  v-for="(item, index) in tableData"
                  :key="index"
                  v-show="tableData.length>0"
                >
                  <div class="list-item df">
                    <span
                      class="tixing"
                      :class="{
                        tixing1: item.qbQuestion.questionType == 0,
                        tixing2: item.qbQuestion.questionType == 1,
                        tixing3: item.qbQuestion.questionType == 2,
                        tixing4: item.qbQuestion.questionType == 3,
                      }"
                      >{{
                        $setDictionary(
                          "QUESTIONTYPE",
                          item.qbQuestion.questionType
                        )
                      }}</span
                    >
                    <div class="subject flex1">
                      <span style="display: flex;justify-content: flex-end;padding-bottom:10px"
                          >试题来源:{{
                            $setDictionary(
                              "QUESTIONORIGIN",
                              item.qbQuestion.questionOrigin
                            )
                          }}</span
                        >
                      <div class="subject-title">
                        <span
                          v-html="$xss(item.qbQuestion.questionTitle)"
                          style="display:flex;"
                          >{{ item.qbQuestion.questionTitle }}</span
                        >
                      </div>
                      <div
                        class="option"
                        v-if="item.qbQuestion.questionType == 0"
                      >
                        <span
                          v-for="(items, indexs) in item.qbQuestionOptions"
                          :key="indexs"
                          >{{ letterArr[indexs] }}.{{ items.optionContent }}
                          <el-image
                            v-if="items.optionSpecialContent"
                            class="qbimg"
                            :src="
                              items.optionSpecialContent ||
                                require('@/assets/develop.png')
                            "
                            fit="contain"
                          ></el-image>
                        </span>
                      </div>
                      <div
                        class="option"
                        v-if="item.qbQuestion.questionType == 1"
                      >
                        <span
                          v-for="(items, indexs) in item.qbQuestionOptions"
                          :key="indexs"
                          >{{ letterArr[indexs] }}.{{ items.optionContent }}
                          <el-image
                            v-if="items.optionSpecialContent"
                            class="qbimg"
                            :src="
                              items.optionSpecialContent ||
                                require('@/assets/develop.png')
                            "
                            fit="contain"
                          ></el-image
                        ></span>
                      </div>
                      <div class="analysis">
                        正确答案：<span
                          v-show="item.qbQuestionOptions.length != 0"
                          v-for="(items, indexs) in item.qbQuestionOptions"
                          :key="indexs"
                          >{{
                            items.optionIsCorrect ? letterArr[indexs] : ""
                          }}</span
                        >
                        <span v-show="item.qbQuestionOptions.length == 0">{{
                          item.qbQuestion.questionAnswer
                        }}</span>
                      </div>
                      <div style="display:flex">
                        <p>答案解析：</p>
                        <span
                          class="analysis img-boxs"
                          style="flex:1"
                          v-html="$xss(item.qbQuestion.questionAnalysis)"
                          >{{ item.qbQuestion.questionAnalysis }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Empty2 slot="empty"  v-show="!tableData.length"/>
            </div>
          </div>
          <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty2 from "@/components/Empty2.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";

export default {
  name: "questionbankAdd",
  components: {
    Empty2,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      letterArr: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "X",
        "Y",
        "Z",
      ],
      currentNumber: -1,
      questionbankName: "",
      areaName: "",
      categoryName:'',
      items: [
        {
          img: require("@/assets/radio.png"),
          name: "单选题",
          id: "0",
        },
        {
          img: require("@/assets/check.png"),
          name: "多选题",
          id: "1",
        },
        {
          img: require("@/assets/judge.png"),
          name: "判断题",
          id: "2",
        },
        {
          img: require("@/assets/fill.png"),
          name: "填空题",
          id: "3",
        },
        
      ],
      TopictypeVal: "",
      questionTitle: "",
      stu: "", // 状态,
      JsonRoute:''
    };
  },
  created: function() {
      this.questionbankName = this.$route.query.questionBankName
      this.questionBankId = this.$route.query.questionBankId
    // if(sessionStorage.getItem("questionJson")){
    //   this.JsonRoute = JSON.parse(sessionStorage.getItem("questionJson"));
    // }
    // // this.JsonRoute = JSON.parse(this.$route.query.json);
    // if(this.$route.query.stu == 'edit') {
    //   this.questionbankName =  this.JsonRoute.questionBankName 
    //   this.questionBankId =  this.JsonRoute.questionBankId 
    //   this.areaName =  this.JsonRoute.areaName 
      this.categoryName = this.$route.query.categoryName
    //   console.log(this.JsonRoute.questionBankId);
    // } else {
    //  this.questionbankName = '' || this.JsonRoute.questionBankName 
    //   this.questionBankId ='' ||  this.JsonRoute.questionBankId 
    //   this.areaName = '' || this.JsonRoute.areaName 
    //   this.categoryName = '' || this.JsonRoute.categoryName
    //    this.getData();
    // }
  
  },
  methods: {
    /* 获取数据start */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        questionBankId: this.$route.query.questionBankId || this.questionBankId
      };
      if (this.TopictypeVal) {
        params.questionType = this.TopictypeVal;
      }
      if (this.questionTitle) {
        params.questionTitle = this.questionTitle;
      }
      this.doFetch(
        {
          url: "/qb/question/pageList",
          params,
          pageNum,
        },
        true,
        2
      );

      // this.getEdit(this.parperId);
    },
    /* end */
    /* 题库类型切换数据 start*/
    clickTap(index, id) {
      this.currentNumber = index;
      this.TopictypeVal = id;
      this.questionTitle = '';
      this.getData();
    },
    currentClass(index) {
      return [this.currentNumber == index ? "addclass" : ""];
    },
    /* end */
    /* 返回 */
    goHerf() {
      this.$router.back()
    },
  },
};
</script>

<style lang="less" scope>
.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-halfBody .framePage-article {
  background-color: #fff;
  margin-right: 0.675rem;
  height: 100%;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
  width: 20%;
  min-width: 20rem;
  .title {
    display: flex;
    justify-content: space-between;
    position: relative;
    h3 {
      font-size: 0.85rem;
    }
    h3:before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -14px;
    }
    .title-button {
      padding: 0;
      font-size: 0.8rem;
    }
  }
  .searchBox {
    display: flex;
    margin: 15px 0;
  }
  .searchqusetionBox {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 50%;
      display: flex;
      margin: 15px 0;
      span {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
      .addclass {
        color: #4574f9;
      }
    }
  }
  .titleMation {
    display: flex;
    flex-direction: column;
    padding: 0.9rem 0 1.8rem 0;
    span:nth-child(2) {
      padding: 0.5rem 0;
    }
  }
  .btnBox {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        display: flex;
        justify-content: space-between;
        .number {
          padding-left: 1rem;
        }
        img{
          width:100px;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
          display: flex;
          align-items: center;
          .qbimg {
            width: 5rem;
          }
        }
      }
    }
  }
}
.img-boxs {
  img {
    width: 100px;
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.informationmBox {
  display: flex;
  justify-content: space-between;
}
.el-divider--horizontal /deep/ {
  margin: 12px 0;
}
.el-cascader /deep/ {
  width: 100%;
}
.el-form-item__content /deep/ .el-upload {
  width: 100px;
  height: 148px !important;
}
.bannerBox .el-form-item__content /deep/ .df {
  display: flex;
}
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card /deep/.el-upload-list__item-actions {
  z-index: 9999;
}
.showbtn {
  display: none;
}
.hoverShow :hover{
  .showbtn{display:block;}
  
}
</style>
